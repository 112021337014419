import { Container, Typography } from "@mui/material";
import Image from "next/image";

interface ErrorProps {
  errorLegend: string;
}

export default function ErrorHandling(props: ErrorProps) {
  return (
    <Container sx={{ mt: 30 }}>
      <Typography
        component={"span"}
        align="center"
        fontSize={{
          lg: 25,
          md: 20,
          sm: 20,
          xs: 20,
        }}
      >
        <p>
          <Image src="/error.png" height={200} width={200} />
        </p>
        <p>Oops! Algo salió mal</p>
        <p>{props.errorLegend}</p>
      </Typography>
    </Container>
  );
}
